import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import SunLogo from "../images/sunxtreme-favicon.png"

export default function NotFoundPage() {
  return (
    <div className="">
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="description" content="This page cannot be found." />
          <meta name="author" content="Sean Rooney" />
          <link rel="icon" href={SunLogo} type="image/gif" sizes="16x16" />
          <link rel="canonical" href="https://sunxtreme.com/404" />
          <title>404</title>
        </Helmet>
        <Container>
          <h1 className="page-header">404- Page not found.</h1>
          Return to home page <Link to="/">here.</Link>
        </Container>
      </Layout>
    </div>
  )
}
